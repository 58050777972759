/** ----------------------------------------
     Handle 429 errors
 ---------------------------------------- */

const handle429 = ($this, error) => {
    if (!error) { return }
    if (!$this) { return }
    if (error.response && error.response.status == 429) {
        console.log('👻 Too many requests, mijnheer 👻');
        $this.dispatch('setToastMessage', 'Too many requests. Please try again later.');
        $this.dispatch('setToastButton', '');
    }
}

/** ----------------------------------------
     Capitalize First Letter
 ---------------------------------------- */

const setTitle = (response) => {
    response.data.data.head_title && (document.title = response.data.data.head_title);
}

/** ----------------------------------------
    Module Export
 ---------------------------------------- */

module.exports = {
    handle429,
    setTitle
};
